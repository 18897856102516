<template>
  <div class="patentDetail">
    <el-row>
      <h1 class="patentDetailTitle">专利基础信息</h1>
    </el-row>
    <el-row class="patentMainContent">
      <el-col :span=8>
        <img :src=patentDetail.imageUrl class="patentImage">
      </el-col>
      <el-col :span=16>
        <el-row>
          <p>专利名称：{{patentDetail.title}}</p>
        </el-row>
        <el-row :gutter=10>
          <el-col :span=12>
            <p>申请号：{{patentDetail.applyNoAll}}</p>
          </el-col>
          <el-col :span=12>
            <p>专利号：{{patentDetail.pubNoAll}}</p>
          </el-col>
        </el-row>
        <el-row>
          <p>申请人：{{patentDetail.applicants.join("，")}}</p>
        </el-row>
        <el-row>
          <p>发明人：{{patentDetail.inventors.join("，")}}</p>
        </el-row>
        <el-row :gutter=10>
          <el-col :span=12>
            <p>专利类型：{{patentDetail.kind}}</p>
          </el-col>
          <el-col :span=12>
            <p>优先权国家：{{patentDetail.country}}</p>
          </el-col>
        </el-row>
        <el-row :gutter=10>
          <el-col :span=12>
            <p>申请日期：{{patentDetail.applyDate.substr(0, 10)}}</p>
          </el-col>
          <el-col :span=12>
            <p>优先权日：{{patentDetail.pubDate.substr(0, 10)}}</p>
          </el-col>
        </el-row>
        <el-row>代理机构：{{patentDetail.agents.join("")}}</el-row>
      </el-col>
    </el-row>
    <el-row>
      <table class="patentDetailTable">
        <tr>
          <th>主分类号</th>
          <th>摘要</th>
        </tr>
        <tr>
          <td>{{patentDetail.ipcrs.join("，")}}</td>
          <td>{{patentDetail.abstractInfo}}</td>
        </tr>
      </table>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "patentDetail",
  data:()=>{
    return{
      patentDocumentValueId:null,
      patentDetail:{
        imageUrl:null,
        applicants:[],
        inventors:[],
        applyDate:"",
        pubDate:"",
        agents:[],
        ipcrs:[]
      },
    }
  },
  mounted() {
    this.initPatentDetail()
  },
  methods:{
    initPatentDetail(){
      this.patentDocumentValueId = this.$route.query.patentDocumentValueId
      this.$axios.get("/custody/patent/query/lanternfishDetail/"+this.patentDocumentValueId)
        .then(response=>{
          this.patentDetail = response.data.data
        })
    }
  }
}
</script>

<style scoped lang="less">
.patentDetail{
  margin: 120px auto;
  width: 1200px;
  padding: 20px;
  border: 1px #666666 solid;
  border-radius: 5px;

  .patentDetailTitle{
    position: relative;
    margin: 20px 30px;
    font-size: 20px;
    font-weight: bolder;
  }
  .patentDetailTitle:before{
    content: "";
    position: absolute;
    top: 0;
    left: -30px;
    height: 35px;
    width: 5px;
    background-color: #3B40FD;
  }

  .patentMainContent{
    .patentImage{
      padding: 0 10%;
      width: 80%;
    }

    p{
      display: inline-block;
      height: 44px;
      line-height: 20px;
    }
  }

  .patentDetailTable{
    margin: 50px 0;
    border-collapse:collapse;

    th{
      padding: 20px;
    }
    th:first-of-type{
      width: 100px;
    }
    td{
      padding: 20px;
      vertical-align: middle;
    }

    tr, th, td{
      border: 1px solid #888888;
    }
  }
}
</style>
